// Home.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import './Home.css';
import Card from '../../components/Card/Card';
import ClientList from '../../components/ClientList/ClientList';
import GetAQuote from '../../components/GetAQuote/GetAQuote';
import CardSlider from '../../components/CardSlider/CardSlider';

import ScrollAnimation from '../../components/ScrollAnimation/ScrollAnimation';
import IncrementalNumber from '../../components/IncrementalNumber/IncrementalNumber';

import featuredProducts from '../../data/featuredProducts';

const Home = () => {
  const aboutUs = [
    {
      title: 'Control System Design',
      imageSrc: '/images/about_us/weighing_system.jpg',
    },
    {
      title: 'Control Panel Assembly',
      imageSrc: '/images/about_us/process_control_system.jpg',
    },
    {
      title: 'Commisioning',
      imageSrc: '/images/about_us/control_panel_assembly.jpg',
    },
    {
      title: 'Training',
      imageSrc: '/images/about_us/engineering_services.jpg',
    },
  ];

  const clients = [
    { src: '/images/clients/unilever.png', alt: 'Unilever' },
    { src: '/images/clients/kapal_api_group.png', alt: 'Kapal Api Group' },
    { src: '/images/clients/wings_group.jpeg', alt: 'Wings Group' },
    { src: '/images/clients/petrokimia_gresik.jpeg', alt: 'Petrokimia Gresik' },
    { src: '/images/clients/pusri.png', alt: 'Pusri' },
    {
      src: '/images/clients/pupuk_kujang_cikampek.png',
      alt: 'Pupuk Kujang Cikampek',
    },
    { src: '/images/clients/pupuk_kaltim.png', alt: 'Pupuk Kaltim' },
    { src: '/images/clients/petrochina.png', alt: 'Petrochina' },
    { src: '/images/clients/pjb.png', alt: 'PLN Nusantara' },
    { src: '/images/clients/smelting.jpeg', alt: 'Smelting' },
    { src: '/images/clients/semen_indonesia.jpeg', alt: 'Semen Indonesia' },
    { src: '/images/clients/cargill.jpeg', alt: 'Cargill' },
    // Add more clients as needed
  ];

  return (
    <>
      <section id='hero' className='col'>
        <div className='row'>
          <div className='col-1'></div>
          <div className='col-10 col-md-6'>
            <div className='row'>
              <div style={{ overflow: 'hidden' }}>
                <ScrollAnimation
                  startImmediately={true}
                  threshold={-1}
                  animation='wipeUp'
                  animationDelay={0}
                >
                  <p className='hero-text mb-0 text-yellow'>
                    <span className='text-yellow'>Weighing Systems</span>
                  </p>
                </ScrollAnimation>
              </div>
              <div style={{ overflow: 'hidden' }}>
                <ScrollAnimation
                  startImmediately={true}
                  threshold={-1}
                  animation='wipeUp'
                  animationDelay={0}
                >
                  <p className='hero-text mb-0 text-yellow'>
                    <span className='text-yellow'>and Automation</span>
                  </p>
                </ScrollAnimation>
              </div>
              <div style={{ overflow: 'hidden' }}>
                <ScrollAnimation
                  startImmediately={true}
                  threshold={-1}
                  animation='wipeUp'
                  animationDelay={0}
                >
                  <p className='smaller mt-0'>For Various Applications</p>
                </ScrollAnimation>
              </div>
            </div>

            <div style={{ overflow: 'hidden' }}>
              <ScrollAnimation
                startImmediately={true}
                threshold={-1}
                animation='wipeUp'
                animationDelay={'quarter'}
              >
                <p className='text-white my-3 lh-2'>
                  We deliver precise and reliable results across industries,
                  including cement, food, power generation, mining, plastics,
                  paper, chemicals, building materials, and wastewater
                  treatment.
                </p>
              </ScrollAnimation>
            </div>
            <br />

            <div style={{ overflow: 'hidden' }}>
              <ScrollAnimation
                startImmediately={true}
                threshold={-1}
                animation='wipeUp'
                animationDelay={'quarter'}
              >
                <a href='#about-us'>
                  <div className='white-button'>Learn More</div>
                </a>
              </ScrollAnimation>
            </div>
          </div>
          <div className='col-1 col-md-5'></div>
        </div>
      </section>

      {/* <section id='partnership' className='col py-5 mt-5'>
        <div className='row'>
          <ScrollAnimation
            startImmediately={true}
            threshold={18}
            animation='fadeIn'
            animationDelay={'half'}
          >
            <div className='col-12'>
              <h1 className='text-center'>Partnership</h1>
            </div>
          </ScrollAnimation>
        </div>
        <div className='row'>
          <div className='col'></div>
          <div className='col'></div>
          <div className='col'></div>
          <div className='col'></div>
        </div>
      </section> */}

      <section id='featured-clients' className='col py-5 mt-5'>
        <div className='row'>
          <ScrollAnimation
            startImmediately={true}
            threshold={25}
            animation='fadeIn'
            animationDelay={'half'}
          >
            <div className='col-12'>
              <h1 className='text-center'>Our Featured Clients</h1>
            </div>
          </ScrollAnimation>
        </div>
        <div id='scrollable-clients-logo' className='mt-4'>
          <ClientList clients={clients} />
        </div>
      </section>

      <section id='about-us' className='col py-5'>
        <div className='row'>
          <div className='col-12'>
            <ScrollAnimation
              startImmediately={false}
              threshold={300}
              animation='fadeIn'
              animationDelay={'half'}
            >
              <h1 className='text-center'>About Us</h1>
            </ScrollAnimation>
          </div>
        </div>
        <div className='row mt-5'>
          <div className='col-1'></div>
          <div className='col-10'>
            <ScrollAnimation
              startImmediately={false}
              threshold={500}
              animation='fadeIn'
              animationDelay={0}
            >
              <div class='row'>
                <div class='col-12 col-md-6 '>
                  <p className=''>
                    Founded in 2003, PT. Global Multi Solusi began its journey
                    in <span>automation system integration</span>. With a
                    committed and dynamic team, the company has grown to handle{' '}
                    <span>turnkey</span> automation projects in major industries
                    like Power Generation,{' '}
                    <span>
                      Petrochemical, Fertilizer, Pulp & Paper, Oil & Gas,
                      Cement, and Mining
                    </span>
                    , and now, we're putting a <span>stronger focus</span> on{' '}
                    <span>process weighing automation</span>.
                  </p>
                  <div className='mt-5'>
                    <ScrollAnimation
                      startImmediately={false}
                      threshold={1000}
                      animation='slideInBottom'
                      animationDelay={0}
                    >
                      <NavLink to='/about-us'>
                        <div className='blue-button mt-2'>Learn More</div>
                      </NavLink>
                    </ScrollAnimation>
                  </div>
                </div>
                <div class='col-12 col-md-6'>
                  <img
                    id='about-us-pic'
                    src='/images/employees/team_photo_logo.png'
                    alt=''
                  />
                </div>
              </div>
              {/* <div className='row'>
                {aboutUs.map((item, index) => (
                  <div className='col-12 col-md-3 p-2' key={index}>
                    <Card {...item} />
                  </div>
                ))}
              </div> */}
            </ScrollAnimation>
          </div>
          <div className='col-1'></div>
        </div>
      </section>

      <section id='homeStatistics' className='my-5 py-5'>
        <div className='row my-5'>
          <div className='col-1'></div>
          <div className='col-10'>
            <div className='row'>
              <div className='col-12 col-md-4 d-flex justify-content-center text-align-center'>
                <img
                  src='/images/icons/building.svg'
                  alt='Sample Image'
                  className='stats-icon mr-4'
                />
                <div className=''>
                  <p className='stats-number mb-0'>
                    <IncrementalNumber
                      startImmediately={false}
                      threshold={1200}
                      targetNumber={2003}
                      suffix=''
                    />
                  </p>
                  <p className='stats-desc mt-0'>Year of Establishment</p>
                </div>
              </div>
              <div className='col-12 col-md-4 d-flex justify-content-center text-align-center'>
                <img
                  src='/images/icons/clipboard_check.svg'
                  alt='Sample Image'
                  className='stats-icon mr-4'
                />
                <div className=''>
                  <p className='stats-number mb-0'>
                    <IncrementalNumber
                      startImmediately={false}
                      threshold={1200}
                      targetNumber={300}
                      suffix='+'
                    />
                  </p>
                  <p className='stats-desc mt-0'>Completed Projects</p>
                </div>
              </div>
              <div className='col-12 col-md-4 d-flex justify-content-center text-align-center'>
                <img
                  src='/images/icons/group.svg'
                  alt='Sample Image'
                  className='stats-icon mr-4'
                />
                <div className=''>
                  <p className='stats-number mb-0'>
                    <IncrementalNumber
                      startImmediately={false}
                      threshold={1200}
                      targetNumber={400}
                      suffix='+'
                    />
                  </p>
                  <p className='stats-desc mt-0'>Total Clients Served</p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-1'></div>
        </div>
      </section>

      <section id='services' className='my-5 py-5'>
        <div className='row my-5'>
          <div className='col-2'></div>
          <div className='col-8'>
            <div className='row'>
              <div className='col-sm-12 col-md-6 d-flex justify-content-center justify-content-md-start mb-4'>
                <ScrollAnimation
                  startImmediately={false}
                  threshold={1500}
                  animation='slideInLeft'
                  animationDelay={0}
                >
                  <div className=''>
                    <h1 className='blue-text text-center text-md-start'>
                      Our Solution
                    </h1>
                    <NavLink to='/services'>
                      <div className='d-flex justify-content-center justify-content-md-start'>
                        <div className='blue-button'>Learn More</div>
                      </div>
                    </NavLink>
                  </div>
                </ScrollAnimation>
              </div>
              <div className='col mt-2'>
                <div className='textcard center'>
                  <img src='/images/icons/process_control_system.png' alt='' />
                  <h3 className=''>Process Control System</h3>
                  <p className=''>
                    From <span>chemical</span> to <span>fertilizer</span>{' '}
                    industries, we offer a <span>proven</span> DCS or PLC
                    platform with years of reliability
                  </p>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col mt-2'>
                <div className='textcard center'>
                  <img src='/images/icons/bulk_handling.png' alt='' />
                  <h3 className=''>Bulk Handling Control System</h3>
                  <p className=''>
                    We offer <span>complete</span> automation system, from{' '}
                    <span>jetty</span> to <span>bulk storage</span>, including
                    <span>ship loader/unloader, stacker/reclaimer</span>, and{' '}
                    <span>conveyor control systems</span>.
                  </p>
                </div>
              </div>
              <div className='col mt-2'>
                <div className='textcard center'>
                  <img src='/images/icons/weighing_system.png' alt='' />
                  <h3 className=''>Weighing System Design</h3>
                  <p className=''>
                    Tailored weighing systems for diverse{' '}
                    <span>bulk materials</span> and <span>applications</span> to
                    meet your handling needs.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-2'></div>
        </div>
      </section>

      <section>
        <ScrollAnimation
          startImmediately={false}
          threshold={2000}
          animation='slideInBottom'
          animationDelay={0}
        >
          <GetAQuote
            bgColor='var(--blue)'
            iconColor='white'
            textColor='white'
          ></GetAQuote>
        </ScrollAnimation>
      </section>

      <section id='featured-products' className='my-5 py-5'>
        <ScrollAnimation
          startImmediately={false}
          threshold={2500}
          animation='slideInBottom'
          animationDelay={0}
        >
          <div className='row my-5'>
            <div className='col-12'>
              <h1 className='text-center'>Our Featured Products</h1>
            </div>
          </div>
          <div className='row mt-4'>
            <div className='col-1'></div>
            <div className='col-10'>
              <div className='row'>
                <CardSlider data={featuredProducts} />
              </div>
              <div className='row mt-5'>
                <div className='col-12 d-flex justify-content-center'>
                  <NavLink to='/products'>
                    <div className='blue-button'>View More</div>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className='col-1'></div>
          </div>
        </ScrollAnimation>
      </section>

      <section></section>
    </>
  );
};

export default Home;

// Products.js
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Products.css';
import { useLocation } from 'react-router-dom';
import ProductCard from '../../components/ProductCard/ProductCard';
import Modal from '../../components/Modal/Modal';
import Dropdown from '../../components/Dropdown/Dropdown';

import ScrollAnimation from '../../components/ScrollAnimation/ScrollAnimation';

import tabs from '../../data/products';

const Products = ({ selectedTabFromMenu }) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(selectedTabFromMenu || tabs[0]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(
    activeTab.categories[0]
  );

  useEffect(() => {
    if (location.state && location.state.selectedTab) {
      setActiveTab(location.state.selectedTab);
      setSelectedCategory(location.state.selectedTab.categories[0]);
    }
  }, [location.state]);

  useEffect(() => {
    if (selectedTabFromMenu) {
      setActiveTab(selectedTabFromMenu);
      setSelectedCategory(selectedTabFromMenu.categories[0]);
    }
  }, [selectedTabFromMenu]);

  return (
    <>
      <section className='page-starter'>
        <div class='row'>
          <div class='col-12'>
            <h1 class='text-center text-white'>Products</h1>
          </div>
        </div>
        <div className='row tabs mt-5'>
          <div className='col-1'></div>
          <div className='col-10'>
            <div className='row'>
              {tabs.map((tab, index) => (
                <div
                  key={tab.id}
                  className={`col-12 col-md tab ${
                    tab.id === activeTab.id ? 'active' : ''
                  }`}
                  id={tab.id}
                  onClick={() => setActiveTab(tab)}
                >
                  <h4>{tab.title}</h4>
                </div>
              ))}
            </div>
          </div>
          <div className='col-1'></div>
        </div>
      </section>

      <section>
        <div class=''>
          <div class='row pt-4'>
            <div class='col-1'></div>
            <div class='col-10 productsHeaderArea'>
              <img
                className='company-logo mb-3'
                src={activeTab.logoSrc}
                alt=''
              />
              <p
                className='mt-2'
                dangerouslySetInnerHTML={{ __html: activeTab.description }}
              ></p>

              <div className='d-md-none'>
                <Dropdown
                  options={activeTab.categories}
                  onSelect={(category) => setSelectedCategory(category)}
                />
              </div>
            </div>
            <div class='col-1'></div>
          </div>
          <div className='row'>
            <div className='col-1'></div>
            <div className='col'>
              <NavLink to='/contact-us'>
                <div className='d-flex d-md-none justify-content-end mt-3'>
                  <div class='yellow-button'>Get A Quote</div>
                </div>
              </NavLink>
            </div>
            <div className='col-1'></div>
          </div>
          <div class='row pt-4'>
            <div class='col-1'></div>
            <div class='col-10'>
              <div class='row'>
                <div class='col'>
                  <div class='productsList'>
                    <div
                      key={`content-${activeTab.id}`}
                      className={`tab-content`}
                      id={`content-${activeTab.id}`}
                    >
                      <div className='row'>
                        {selectedCategory === 'All'
                          ? // Render all products if 'All' category is selected
                            activeTab.products &&
                            activeTab.products.map((product) => (
                              <div
                                key={product.id}
                                className='col-12 col-md-4 my-3 cardContainer'
                              >
                                <ProductCard
                                  id={product.id}
                                  title={product.name}
                                  imageSrc={
                                    product.imageSrc !== ''
                                      ? product.imageSrc
                                      : activeTab.logoSrc
                                  }
                                  description={product.desc}
                                  onClick={() => setSelectedProduct(product)}
                                />
                              </div>
                            ))
                          : // Render products matching the selected category
                            activeTab.products &&
                            activeTab.products
                              .filter(
                                (product) =>
                                  product.category === selectedCategory
                              )
                              .map((product) => (
                                <div
                                  key={product.id}
                                  className='col-12 col-md-4 my-3 cardContainer'
                                >
                                  <ProductCard
                                    id={product.id}
                                    title={product.name}
                                    imageSrc={
                                      product.imageSrc !== ''
                                        ? product.imageSrc
                                        : activeTab.logoSrc
                                    }
                                    description={product.desc}
                                    onClick={() => setSelectedProduct(product)}
                                  />
                                </div>
                              ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div class='d-none d-md-block col-md-3'>
                  <ScrollAnimation
                    startImmediately={false}
                    threshold={0}
                    animation='slideInBottom'
                    animationDelay={0}
                  >
                    <h3 className='mt-5'>
                      <span>How Can We Help?</span>
                    </h3>
                    <NavLink to='/contact-us'>
                      <div class='yellow-button'>Get A Quote</div>
                    </NavLink>
                    <p className='cat mt-4'>Categories</p>
                    <div className='col d-none d-md-flex align-items-end text-start'>
                      <Dropdown
                        options={activeTab.categories}
                        onSelect={(category) => setSelectedCategory(category)}
                      />
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
            <div class='col-1'></div>
          </div>
        </div>
      </section>

      {selectedProduct && (
        <Modal
          title={selectedProduct.name}
          content={selectedProduct.desc}
          imgSrc={selectedProduct.imageSrc}
          src={selectedProduct.src}
          onClose={() => setSelectedProduct(null)}
          getAQuoteEnabled={
            selectedProduct.id.startsWith('rockwell') ? false : true
          }
        />
      )}
    </>
  );
};

export default Products;

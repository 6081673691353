import React, { useState, useEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.css';
import { NavLink } from 'react-router-dom';

import productTabs from '../../data/products';
import serviceTabs from '../../data/services';

const Header = ({ setProductTabFromMenu, setServiceTabFromMenu }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isProductsSubNavOpen, setIsProductsSubNavOpen] = useState(false);
  const [isServicesSubNavOpen, setIsServicesSubNavOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [topNavVisible, setTopNavVisible] = useState(true);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const closeNav = () => {
    setIsNavOpen(false);
  };

  const toggleProductsSubNav = () => {
    setIsProductsSubNavOpen(!isProductsSubNavOpen);
  };

  const toggleServicesSubNav = () => {
    setIsServicesSubNavOpen(!isServicesSubNavOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setTopNavVisible(
        prevScrollPos >= currentScrollPos || currentScrollPos < 10
      );
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  return (
    <>
      <div className='navContainer'>
        <div className={`row navbar-top ${topNavVisible ? '' : 'hidden'}`}>
          <div className='col-11 d-md-flex justify-content-end mx-auto'>
            <div className='d-flex align-items-center justify-content-end'>
              <img
                className='icon'
                src='/images/icons/header_mail.svg'
                alt=''
              />
              <a
                target='_blank'
                href='mailto:sales@globalmultisolusi.com'
                className='btn btn-link text-white'
              >
                sales@globalmultisolusi.com
              </a>
            </div>
            <div className='d-flex align-items-center justify-content-end'>
              <img
                className='icon'
                src='/images/icons/header_phone.svg'
                alt=''
              />
              <a
                target='_blank'
                href='tel:+6231566 4551'
                className='btn btn-link text-white '
              >
                +62 31 566 4551
              </a>
            </div>
          </div>
        </div>

        {/* Mobile Navigation */}
        <div className='row navbar-bottom d-md-none'>
          <div className='col-1'></div>
          <div className='col-10'>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='menu'>
                <NavLink to='/'>
                  <img className='logo' src='/images/logo/white.png' alt='' />
                </NavLink>
              </div>
              <div className='menu'>
                <div className='navbar-toggler' onClick={toggleNav}>
                  <img
                    src={
                      isNavOpen
                        ? '/images/icons/menu_bars_yellow.svg'
                        : '/images/icons/menu_bars_white.svg'
                    }
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Expanded Mobile Navigation */}
        {isNavOpen && (
          <div className='row navbar-mobile-collapse d-md-none'>
            <div className='col-12 mobileMenus'>
              <div className='mobileMenu'>
                <NavLink to='/' className='navlink mb-1' onClick={closeNav}>
                  Home
                </NavLink>
              </div>
              <div className='mobileMenu'>
                <NavLink
                  to='/products'
                  className='navlink mb-1'
                  onClick={closeNav}
                >
                  Products
                </NavLink>
              </div>
              <div className='mobileMenu'>
                <NavLink
                  to='/services'
                  className='navlink mb-1'
                  onClick={closeNav}
                >
                  Solution
                </NavLink>
              </div>
              <div className='mobileMenu'>
                <NavLink
                  to='/contact-us'
                  className='navlink mb-1'
                  onClick={closeNav}
                >
                  Contact Us
                </NavLink>
              </div>
              <div className='mobileMenu'>
                <NavLink
                  to='/about-us/'
                  className='navlink mb-1'
                  onClick={closeNav}
                >
                  About Us
                </NavLink>
              </div>
            </div>
          </div>
        )}

        <div className='row navbar-bottom d-none d-md-flex'>
          <div className='col-1'></div>
          <div className='col-7'>
            <div className='row'>
              <dic className='col d-flex '>
                <div className='menu'>
                  <NavLink to='/' className=''>
                    <img className='logo' src='/images/logo/white.png' alt='' />
                  </NavLink>
                </div>
                <div
                  className='menu'
                  onMouseEnter={toggleProductsSubNav}
                  onMouseLeave={toggleProductsSubNav}
                >
                  <NavLink className='navlink' to='/products'>
                    <p>Products</p>
                  </NavLink>
                  {isProductsSubNavOpen && (
                    <div className='sub-nav'>
                      {productTabs.map((tab, index) => (
                        <div key={index}>
                          <NavLink
                            to={{
                              pathname: '/products',
                              state: { selectedTab: tab },
                            }}
                            onClick={() => setProductTabFromMenu(tab)}
                          >
                            {tab.title}
                          </NavLink>
                        </div>
                      ))}
                    </div>
                  )}
                  {isProductsSubNavOpen ? (
                    <img
                      className='arrow up'
                      src='/images/icons/arrow_up_yellow.svg'
                    ></img>
                  ) : (
                    <img
                      className='arrow down'
                      src='/images/icons/arrow_down_white.svg'
                    ></img>
                  )}
                  <svg
                    width='1'
                    height='20'
                    viewBox='0 0 1 10'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <line
                      x1='0.5'
                      y1='0'
                      x2='0.5'
                      y2='20'
                      stroke='gray'
                      stroke-width='1'
                    />
                  </svg>
                </div>
                <div
                  className='menu'
                  onMouseEnter={toggleServicesSubNav}
                  onMouseLeave={toggleServicesSubNav}
                >
                  <NavLink className='navlink' to='/services'>
                    <p>Solution</p>
                  </NavLink>
                  {isServicesSubNavOpen && (
                    <div className='sub-nav'>
                      {serviceTabs.map((tab, index) => (
                        <div key={index}>
                          <NavLink
                            to={{
                              pathname: '/services',
                              state: { selectedTab: tab },
                            }}
                            onClick={() => setServiceTabFromMenu(tab)}
                          >
                            {tab.title}
                          </NavLink>
                        </div>
                      ))}
                    </div>
                  )}
                  {isServicesSubNavOpen ? (
                    <img
                      className='arrow up'
                      src='/images/icons/arrow_up_yellow.svg'
                    ></img>
                  ) : (
                    <img
                      className='arrow down'
                      src='/images/icons/arrow_down_white.svg'
                    ></img>
                  )}
                  <svg
                    width='1'
                    height='20'
                    viewBox='0 0 1 10'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <line
                      x1='0.5'
                      y1='0'
                      x2='0.5'
                      y2='20'
                      stroke='gray'
                      stroke-width='1'
                    />
                  </svg>
                </div>
                <div className='menu'>
                  <NavLink className='navlink' to='/contact-us'>
                    <p>Contact Us</p>
                  </NavLink>
                  <img
                    className='arrow down'
                    src='/images/icons/arrow_down_white.svg'
                  ></img>
                  <img
                    className='arrow up d-none'
                    src='/images/icons/arrow_up_white.svg'
                  ></img>
                  <svg
                    width='1'
                    height='20'
                    viewBox='0 0 1 10'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <line
                      x1='0.5'
                      y1='0'
                      x2='0.5'
                      y2='20'
                      stroke='gray'
                      stroke-width='1'
                    />
                  </svg>
                </div>
                <div className='menu'>
                  <NavLink className='navlink' to='/about-us'>
                    <p>About Us</p>
                  </NavLink>
                  <img
                    className='arrow down'
                    src='/images/icons/arrow_down_white.svg'
                  ></img>
                  <img
                    className='arrow up d-none'
                    src='/images/icons/arrow_up_white.svg'
                  ></img>
                  <svg
                    width='1'
                    height='20'
                    viewBox='0 0 1 10'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <line
                      x1='0.5'
                      y1='0'
                      x2='0.5'
                      y2='20'
                      stroke='gray'
                      stroke-width='1'
                    />
                  </svg>
                </div>
              </dic>
            </div>
          </div>
          <div className='col-3'>
            <div className='row'>
              <div className='col'></div>
              <div className='col text-end'>
                <NavLink to='/contact-us'>
                  <div className='col text-end center'>
                    <div className='yellow-button'>Get A Quote</div>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
          <div className='col-1'></div>
        </div>
      </div>
    </>
  );
};

export default Header;
